import React from 'react';
import { STYLE_CLASSES } from '../../styles';
import { ATTRIBUTES } from '../../data/attributes';

interface ButtonAtomProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
}

const ButtonAtom = React.forwardRef<HTMLButtonElement, ButtonAtomProps>(({ text, ...props }, ref) => (
  <button
    type={ATTRIBUTES.atoms.button.type}
    aria-label={ATTRIBUTES.atoms.button.ariaLabel}
    ref={ref}
    className={STYLE_CLASSES.component.atoms.button}
    
    {...props}
  >
    {text}
  </button>
));

export default ButtonAtom;
