import React from 'react';
import classNames from 'classnames';
import { baseStyles } from '@entrycall/ui-tailwind';
import { STYLE_CLASSES } from '../../../styles';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  name: string;
  type: string;
  placeholder: string;
  isInvalid: boolean;
}

const AuthInput: React.FC<InputProps> = ({
  id,
  name,
  type,
  placeholder,
  isInvalid,
  onChange,
  ...rest
}) => {
  return (
    <input
      id={id}
      name={name}
      type={type}
      placeholder={placeholder}
      aria-describedby={`${id}-error`}
      autoComplete="on"
      className={classNames(
        STYLE_CLASSES.component.atoms.auth.input.base,
        STYLE_CLASSES.component.atoms.auth.input.textColor(isInvalid),
        STYLE_CLASSES.component.atoms.auth.input.focusStyles
      )}
      onChange={onChange}
      {...rest}
    />
  );
};

export default AuthInput;