import React, { useState } from 'react';
import { FORM_INPUT_NAME_PHONE } from '../../../../types';
import AuthInputFields from '../input_fields';
import { STYLE_CLASSES } from '../../../../styles';
import { DATA_TEXT } from '../../../../data/text';
import { ATTRIBUTES } from '../../../../data/attributes';

// Props interface for the PhoneInputField component
type PhoneInputFieldProps = {
  error: string | null;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * PhoneInputField Component
 *
 * This component renders an input field specifically for phone number input, including
 * a phone icon and handling error display.
 *
 * @param {PhoneInputFieldProps} props - The properties for this component.
 * @returns {JSX.Element} The rendered phone input field component.
 */
const PhoneInputField: React.FC<PhoneInputFieldProps> = ({ error, onChange }) => {
  const [value, setValue] = useState('');
  const isInvalid = !!error;

  // Use the formatPhoneNumber utility to format the error message if it contains a phone number
  const errorMessage = error ? formatPhoneNumber(error) : null;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;

    // Remove all non-digit characters
    inputValue = inputValue.replace(/\D/g, '');

    // Format with parentheses and dashes (e.g., (123) 456-7890)
    if (inputValue.length > 3 && inputValue.length <= 6) {
      inputValue = `(${inputValue.slice(0, 3)}) ${inputValue.slice(3)}`;
    } else if (inputValue.length > 6) {
      inputValue = `(${inputValue.slice(0, 3)}) ${inputValue.slice(3, 6)}-${inputValue.slice(6, 10)}`;
    }

    setValue(inputValue);
    onChange(e); // Call the passed onChange handler
  };

  return (
    <AuthInputFields
      id={ATTRIBUTES.molecules.auth.phone.inputFields.id} // Unique identifier for the input field
      name={ATTRIBUTES.molecules.auth.phone.inputFields.name} // Name attribute for the input, used in form submissions
      type={ATTRIBUTES.molecules.auth.phone.inputFields.type} // Specifies that the input field is for email addresses
      placeholder={ATTRIBUTES.molecules.auth.phone.inputFields.placeholder} // Placeholder text shown inside the input field
      aria-describedby={ATTRIBUTES.molecules.auth.phone.inputFields.ariaDescribedby}
      icon={
        <svg
          className={STYLE_CLASSES.component.molecules.auth.phone.inputFields.icon(isInvalid)}
          aria-hidden={ATTRIBUTES.molecules.auth.phone.inputFields.icon.ariaHidden}
          xmlns={ATTRIBUTES.molecules.auth.phone.inputFields.icon.xmlns}
          fill={ATTRIBUTES.molecules.auth.phone.inputFields.icon.fill}
          viewBox={ATTRIBUTES.molecules.auth.phone.inputFields.icon.viewBox}
        >
          <path d="M18 13.446a3.02 3.02 0 0 0-.946-1.985l-1.4-1.4a3.054 3.054 0 0 0-4.218 0l-.7.7a.983.983 0 0 1-1.39 0l-2.1-2.1a.983.983 0 0 1 0-1.389l.7-.7a2.98 2.98 0 0 0 0-4.217l-1.4-1.4a2.824 2.824 0 0 0-4.218 0c-3.619 3.619-3 8.229 1.752 12.979C6.785 16.639 9.45 18 11.912 18a7.175 7.175 0 0 0 5.139-2.325A2.9 2.9 0 0 0 18 13.446Z" />
        </svg>
      }
      errorText={errorMessage}
      isInvalid={isInvalid}
      maxLength={ATTRIBUTES.molecules.auth.phone.inputFields.maxLength}
      onChange={handleInputChange}
      value={value}
      required={ATTRIBUTES.molecules.auth.phone.inputFields.required}
    />
  )
}

export default PhoneInputField;

/**
 * Formats a phone number within a message string to the format +1 (123) 456-7890.
 *
 * @param message - The input message containing the phone number. The phone number is expected to be in the format +11234567890.
 * @returns The message with the phone number formatted as +1 (123) 456-7890.
 */
const formatPhoneNumber = (message: string): string => {
  const regex = /(\+\d)(\d{3})(\d{3})(\d{4})/;
  const formattedMessage = message.replace(
    regex,
    (_, p1, p2, p3, p4) => `${p1} (${p2}) ${p3}-${p4}`,
  );
  // console.log('Original message:', message);
  // console.log('Formatted message:', formattedMessage);
  return formattedMessage;
};
