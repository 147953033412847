import { FORM_INPUT_NAME_EMAIL, FORM_INPUT_NAME_PHONE } from "../../../backend/types";
import { DATA_TEXT } from "../text";

export const ATTRIBUTES = {
    atoms: {
        auth: {
            type: 'submit',
            ariaLabel: 'Submit',
        },
        verify: {
            otpInput: {
                type: 'text',
                name: (index: number) => `verification-code-${index}`,
                pattern: '^[0-9]*$',
                maxLength: 1,
                required: true, 
                ariaLabel: 'OTP Input',
            }
        },
        button: {
            type: 'submit',
            ariaLabel: 'Submit', 
        }
    },
    molecules: {
        auth: {
            email: {    
                inputFields: {
                    id: 'email',
                    name: FORM_INPUT_NAME_EMAIL,
                    type: 'email',
                    placeholder: DATA_TEXT.auth.component.molecules.email.inputFields.placeholder,
                    required: true,
                    ariaDescribedby: 'email-helper-text', 
                }
            },
            phone: {
                inputFields: {
                    id: 'phone',
                    name: FORM_INPUT_NAME_PHONE,
                    type: 'text',
                    placeholder: DATA_TEXT.auth.component.molecules.phone.inputFields.placeholder,
                    ariaDescribedby: 'helper-text-explanation',
                    icon: {
                        id: 'phone-icon',
                        ariaHidden: 'true',
                        xmlns: 'http://www.w3.org/2000/svg',
                        fill: 'currentColor',
                        viewBox: '0 0 19 18',
                    },
                    maxLength: 14,
                    required: true,
                },
            }
        },
        verify: {},
        header: {
            svg:{
                ariaLabelledby: 'Logo',
                width: 250,
                height: 100,
                viewBox: '0 0 876 100',
                xmlns: 'http://www.w3.org/2000/svg',
            }
        },
    },
    organisms: {
        auth: {
            form: {
                Form: {
                    id: 'auth-form',
                    method: 'post',
                    ariaLabel: 'Auth Form',
                },
            },
        },
        verify: {
            form: {
                Form: {
                    id: 'otp-form',
                    method: 'post',
                    ariaLabel: 'OTP Form',
                },
            },
            resend: {
                useSubmit: {
                    method: 'post',
                    action: (method: string | null, data: string | null) => `/auth/login?resend=true&method=${method}&data=${data}`,
                },
            },
        },
    },
} as const;
