import React, { useState, useEffect } from 'react';
import { useNavigation } from '@remix-run/react';
import SectionWrapper from '../main-wrapper.ui';
import { SpinnerComponent } from '@entrycall/component-spinner';
import OtpForm from '../../components/templates/verify/verify-form';
import ArticleWrapper from '../../components/templates/section-wrapper.ui';
import { DATA_TEXT } from '../../data/text';

const VerifyPage: React.FC = () => {
  const navigation = useNavigation();
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [loadingTime, setLoadingTime] = useState<number>(0);

  const isNavigating = navigation.state === 'loading';

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isNavigating) {
      timer = setInterval(() => {
        setLoadingTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      setLoadingTime(0);
      setLoadingMessage('');
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [isNavigating]);

  useEffect(() => {
    setLoadingMessage(DATA_TEXT.verify.page.loadingTime(loadingTime));
  }, [loadingTime]);

  return (
    <SectionWrapper>
      {isNavigating ? (
        <ArticleWrapper>
          <SpinnerComponent />
          {loadingMessage &&  <p>{loadingMessage}</p>}
        </ArticleWrapper>
      ) : (
        <OtpForm />
      )}
    </SectionWrapper>
  );
};

export default VerifyPage;