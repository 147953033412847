import React from 'react';
import HeaderMolecule from '../header.molecules.ui';
import { DATA_TEXT } from '../../../data/text';

const HeaderAuth: React.FC = () => {
  return (
    <HeaderMolecule
      title={DATA_TEXT.auth.component.molecules.header.title}
      description={DATA_TEXT.auth.component.molecules.header.description}
    />
  );
};

export default HeaderAuth;
