import { STYLE_CLASSES } from '../style';

export const PingSpinnerComponent = () => {
  return (
    <div className={STYLE_CLASSES.ping.container}>
      <span className={STYLE_CLASSES.ping.pingWrapper}>
        <span className={STYLE_CLASSES.ping.pingAnimation}></span>
        <span className={STYLE_CLASSES.ping.pingDot}></span>
      </span>
    </div>
  );
};
