
import React from 'react';
import { STYLE_CLASSES } from '../../../styles';
import { ATTRIBUTES } from '../../../data/attributes';

interface OtpInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string; // The value of the OTP input
  index: number; // The index of the input in the OTP sequence
  inputRef: (el: HTMLInputElement | null) => void; // Ref callback for the input element
  handleChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void; // Handler for change events
  handleKeyDown: (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => void; // Handler for key down events
  handleFocus: (e: React.FocusEvent<HTMLInputElement>) => void; // Handler for focus events
}

/**
 * OtpInput component - A single input field for entering a single digit in an OTP sequence.
 * @param {string} value - The value of the OTP input.
 * @param {number} index - The index of the input in the OTP sequence.
 * @param {(el: HTMLInputElement | null) => void} inputRef - Ref callback for the input element.
 * @param {(e: React.ChangeEvent<HTMLInputElement>, index: number) => void} handleChange - Handler for change events.
 * @param {(e: React.KeyboardEvent<HTMLInputElement>, index: number) => void} handleKeyDown - Handler for key down events.
 * @param {(e: React.FocusEvent<HTMLInputElement>) => void} handleFocus - Handler for focus events.
 * @param {Object} props - Additional props passed to the input element.
 */
const OtpInput: React.FC<OtpInputProps> = ({
  value,
  index,
  inputRef,
  handleChange,
  handleKeyDown,
  handleFocus,
  ...props
}) => {

  return (
    <input
      className={STYLE_CLASSES.component.atoms.verify.otpInput}
      type={ATTRIBUTES.atoms.verify.otpInput.type}
      name={ATTRIBUTES.atoms.verify.otpInput.name(index)}
      ref={inputRef}
      pattern={ATTRIBUTES.atoms.verify.otpInput.pattern}
      maxLength={ATTRIBUTES.atoms.verify.otpInput.maxLength}
      value={value}
      onChange={(e) => handleChange(e, index)}
      onKeyDown={(e) => handleKeyDown(e, index)}
      onFocus={handleFocus}
      required={ATTRIBUTES.atoms.verify.otpInput.required}
      aria-label={ATTRIBUTES.atoms.verify.otpInput.ariaLabel}
      {...props}
    />
  );
};

export default OtpInput;
