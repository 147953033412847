import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { STYLE_CLASSES } from '../../../styles';


const AuthErrorIcon = () => (
  <div className={STYLE_CLASSES.component.atoms.auth.authErrorIcon.errorIconContainer}>
    <ExclamationCircleIcon aria-hidden="true" className={STYLE_CLASSES.component.atoms.auth.authErrorIcon.errorIcon} />
  </div>
);

export default AuthErrorIcon;
