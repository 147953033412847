export const DATA_TEXT = {
    auth: {
        component: {
            molecules: {
                email: {
                    inputFields: {
                        placeholder: 'you@example.com',
                    },
                },
                phone: {
                    inputFields: {
                        placeholder: '(123) 456-7890',
                    },
                },
                header: {
                    title: "Welcome Dear Tenant",
                    description: "Login in with",
                },
            },
            organisms: {
                form: {
                    emailTab: 'Email',
                    phoneTab: 'Phone',
                    loadingButtonText: 'Loading...',
                    loginButtonText: 'Login',
                    unexpectedError: 'An unexpected error occurred',
                },
                header: {
                    title: (data: string | null) => `Code sent to ${data}`,
                },
            },
        },
    },
    verify: {
        component: {
            organisms: {
                verifyAccount: 'Verify Account',
                loading: 'Loading...',
                didntReceiveCode: "Didn't receive code?",
                resend: 'Resend',
                codeResent: 'Code resent successfully',
                unexpectedError: 'An unexpected error occurred',
                codeExpired: 'Code expired',
                codeExpiresIn: 'Code expires in:',
                descriptionPhone: (data: string) => `Enter the 6-digit verification code sent to your phone number ${data}.`,
                descriptionEmail: (data: string) => `Enter the 6-digit verification code sent to your email address ${data}.`,
                descriptionDefault: 'Enter the 6-digit verification code that was sent to your phone number or email address.',
            },
        },
        page: {
            loadingTime: (loadingTime: number): string => {
                if (loadingTime > 40) {
                    return 'We apologize for the delay. Please wait a bit longer...';
                }
                switch (loadingTime) {
                    case 3: return 'Just a moment...';
                    case 9: return 'Almost there...';
                    case 15: return 'Hang on, this is taking longer than expected...';
                    case 21: return "We're working on it...";
                    case 27: return 'Thank you for your patience...';
                    default: return '';
                }
            },
        },
    },
} as const;
