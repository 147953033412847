import React from 'react';
import LogoGif from '../img/transparent_loader.gif';
import { STYLE_CLASSES } from '../style';

interface SpinnerComponentProps {
  size?: 'small' | 'medium' | 'large';
  customClass?: string;
}

/**
 * SpinnerComponent
 *
 * A customizable loading spinner component that displays a rotating logo.
 *
 * @component
 * @param {Object} props - The component props
 * @param {string} [props.customClass=''] - Additional CSS classes to apply to the container
 *
 * @returns {React.ReactElement} A div containing the spinning logo image
 *
 * @example
 * <SpinnerComponent size="large" customClass="my-custom-class" />
 */
export const SpinnerComponent: React.FC<SpinnerComponentProps> = ({
  size = 'medium',
  customClass = '',
}) => {
  return (
    <div className={STYLE_CLASSES.default.container(customClass)}>
      <img src={LogoGif} alt="Loading..." className={STYLE_CLASSES.default.img} />
    </div>
  );
};
