// lib/routes/auth/src/lib/frontend/components/organisms/auth/auth-form.tsx
import { useState, useEffect } from 'react';
import { Form, useActionData, useNavigation, type NavigationType } from '@remix-run/react';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';

import HeaderMolecule from '../../molecules/auth/auth-header';
import ButtonAtom from '../../atoms/button.atom.ui';
import EmailInputField from '../../molecules/auth/email/email-Input_fields';
import PhoneInputField from '../../molecules/auth/phone/phone-Input';
import { type ActionData } from '../../../types';
import { isMobile } from '@entrycall/utility-screen-size-checker';
import { STYLE_CLASSES } from '../../../styles';
import { DATA_TEXT } from '../../../data/text';
import { ATTRIBUTES } from '../../../data/attributes';



const AuthFormOrganism: React.FC = () => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const data = useActionData<ActionData>();
  const navigation = useNavigation<NavigationType>();
  const [error, setError] = useState<string | null>(null);
  const [isMobileView, setIsMobileView] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      const errorMessage = data.details?.errorMessage;
      const error = data.error;

      if (typeof errorMessage === 'string' && errorMessage.trim() !== '') {
        setError(errorMessage);
      } else if (typeof error === 'string' && error.trim() !== '') {
        setError(error);
      } else {
        setError(DATA_TEXT.auth.component.organisms.form.unexpectedError);
      }
    } else {
      setError(null);
    }
  }, [data]);

  useEffect(() => {
    // Update the isMobileView state on component mount to ensure consistent client-side rendering
    setIsMobileView(isMobile());
  }, []);

  const handleChange = () => {
    setError(null);
  };

  const handleTabChange = (index: number) => {
    setSelectedIndex(index);
    setError(null); // Clear error when switching tabs
  };

  const buttonText = navigation.state === 'submitting' ? DATA_TEXT.auth.component.organisms.form.loadingButtonText : DATA_TEXT.auth.component.organisms.form.loginButtonText;

  return (
    <article className={STYLE_CLASSES.component.organisms.auth.form.article}>
      <HeaderMolecule />
      <Form method={ATTRIBUTES.organisms.auth.form.Form.method} 
      id={ATTRIBUTES.organisms.auth.form.Form.id} 
      className={STYLE_CLASSES.component.organisms.auth.form.form}
      aria-label={ATTRIBUTES.organisms.auth.form.Form.ariaLabel}>
        <TabGroup selectedIndex={selectedIndex} onChange={handleTabChange}>
          <TabList className={STYLE_CLASSES.component.organisms.auth.form.tabGroup}>
            <Tab className={STYLE_CLASSES.component.organisms.auth.form.tab}>{DATA_TEXT.auth.component.organisms.form.emailTab}</Tab>
            <Tab className={STYLE_CLASSES.component.organisms.auth.form.tab}>{DATA_TEXT.auth.component.organisms.form.phoneTab}</Tab>
          </TabList>
          <TabPanels className={STYLE_CLASSES.component.organisms.auth.form.tabPanels}>
            <TabPanel className={STYLE_CLASSES.component.organisms.auth.form.tabPanel}>
              <EmailInputField
                error={selectedIndex === 0 ? error : null}
                onChange={handleChange}
              />
            </TabPanel>
            <TabPanel className={STYLE_CLASSES.component.organisms.auth.form.tabPanel}>
              <PhoneInputField
                error={selectedIndex === 1 ? error : null}
                onChange={handleChange}
              />
            </TabPanel>
          </TabPanels>
        </TabGroup>
        <div className={STYLE_CLASSES.component.organisms.auth.form.buttonWrapper(isMobileView)}>
          <ButtonAtom text={buttonText} />
        </div>
      </Form>
    </article>
  );
};

export default AuthFormOrganism;