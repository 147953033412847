// lib/routes/auth/src/lib/frontend/components/molecules/auth/email/email-Input_fields.tsx

import React from 'react';
import AuthInputFields from '../input_fields'; // Importing the base input fields component
import { EnvelopeIcon } from '@heroicons/react/20/solid'; // Importing the icon for the email input
import { STYLE_CLASSES } from '../../../../styles';
import { ATTRIBUTES } from '../../../../data/attributes';
// Props interface for the EmailInputField component
type EmailInputFieldProps = {
  error: string | null;
  onChange: () => void;
}

/**
 * EmailInputField Component
 *
 * This component renders an input field specifically for email input, including
 * an email icon and handling error display. It uses the AuthInputFields component
 * for consistent styling and behavior with other input fields.
 *
 * @param {EmailInputFieldProps} props - The properties for this component.
 * @returns {JSX.Element} The rendered email input field component.
 */
const EmailInputField: React.FC<EmailInputFieldProps> = ({ error, onChange }) => {
  const isInvalid = !!error;

  return (
    <AuthInputFields
      id={ATTRIBUTES.molecules.auth.email.inputFields.id} // Unique identifier for the input field
      name={ATTRIBUTES.molecules.auth.email.inputFields.name} // Name attribute for the input, used in form submissions
      type={ATTRIBUTES.molecules.auth.email.inputFields.type} // Specifies that the input field is for email addresses
      placeholder={ATTRIBUTES.molecules.auth.email.inputFields.placeholder} // Placeholder text shown inside the input field
      icon={<EnvelopeIcon className={STYLE_CLASSES.component.molecules.auth.email.inputFields.icon(isInvalid)} />} // Icon displayed in the input field
      errorText={error} // The error message text to display, if any
      isInvalid={isInvalid} // Boolean to indicate if the input is in an invalid state
      onChange={onChange} // Function to handle input changes
      required={ATTRIBUTES.molecules.auth.email.inputFields.required}
      aria-describedby={ATTRIBUTES.molecules.auth.email.inputFields.ariaDescribedby}
    />
  );
};

export default EmailInputField;
