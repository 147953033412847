import { STYLE_CLASSES } from '../../../styles';

interface ErrorMessageProps {
  id?: string;
  errorText?: string | null;
}

const ErrorMessage = ({ id, errorText }: ErrorMessageProps) => (
  <p id={`${id}-error`} className={STYLE_CLASSES.component.atoms.auth.errorMessage}>
    {errorText}
  </p>
);

export default ErrorMessage;
