export const FORM_INPUT_NAME_EMAIL = 'email';
export const FORM_INPUT_NAME_PHONE = 'phone';

export interface ActionData {
  error?: string;
  details?: {
    errorMessage?: string;
  };
}

