import React from 'react';
import { STYLE_CLASSES } from '../../styles';

interface ArticleWrapperProps {
  className?: string; // Additional custom classes for the section
  children: React.ReactNode; // Children components to be rendered inside the section
}

/**
 * SectionWrapper component - A reusable wrapper for sections, providing consistent styling.
 * This component is designed specifically for use with the OtpForm and AuthForm components,
 * ensuring a consistent layout and appearance across these forms.
 * @param {string} [className] - Optional additional custom classes for the section.
 * @param {React.ReactNode} children - The children components to be rendered inside the section.
 */
const SectionWrapper: React.FC<ArticleWrapperProps> = ({className, children,}) => {
  
  return <section className={STYLE_CLASSES.templates.sectionWrapper(className)}>{children}</section>;
};

export default SectionWrapper;
