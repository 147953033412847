import React from 'react';
import { STYLE_CLASSES } from '../../../styles';

type LabelProps = {
  htmlFor: string;
  children?: React.ReactNode;
}

const AuthLabel = ({ htmlFor, children }: LabelProps) => (
  <label htmlFor={htmlFor} className={STYLE_CLASSES.component.atoms.auth.label}>
    {children}
  </label>
);

export default AuthLabel;
