
import React from 'react';
import { STYLE_CLASSES } from '../../../styles';

interface IconWrapperProps {
  children: React.ReactNode;
}

const IconWrapper = ({ children }: IconWrapperProps) => (
  <div className={STYLE_CLASSES.component.atoms.auth.iconWrapper}>
    {children}
  </div>
);

export default IconWrapper;
