// lib/routes/auth/src/lib/frontend/components/organisms/verify/verify-form.tsx

import { useEffect, useRef, useState } from 'react';
import {
  Form,
  useActionData,
  useLoaderData,
  useNavigation,
  useSubmit,
  type SubmitFunction,
  type NavigationType,
} from '@remix-run/react';
import HeaderMolecule from '../../molecules/verify/verify-header';
import OtpInputs from '../../molecules/verify/otp-inputs';
import ButtonAtom from '../../atoms/button.atom.ui';
import ErrorMessage from '../../atoms/auth/error_message-auth.atom.ui';
import { isMobile } from '@entrycall/utility-screen-size-checker';
import {
  FORM_INPUT_NAME_EMAIL,
  FORM_INPUT_NAME_PHONE,
  type ActionData
} from '../../../types';
import { STYLE_CLASSES } from '../../../styles';
import { DATA_NUMERIC } from '../../../data/numeric';
import { DATA_TEXT } from '../../../data/text';
import { ATTRIBUTES } from '../../../data/attributes';

const OtpFormOrganism = () => {
  const { method, data } = useLoaderData<{
    method: string | null;
    data: string | null;
  }>();

  const submitButtonRef = useRef<HTMLButtonElement | null>(null);
  const submit = useSubmit<SubmitFunction>();
  const navigation = useNavigation<NavigationType>();
  const actionData = useActionData<ActionData>();
  const [resendCount, setResendCount] = useState<number>(DATA_NUMERIC.verify.resendCountInitial);
  const [timeLeft, setTimeLeft] = useState<number>(DATA_NUMERIC.verify.timeLeftInitial);
  const [errorText, setError] = useState<string | null>(null);
  const [isMobileView, setIsMobileView] = useState<boolean>(false);
  const [resendMessage, setResendMessage] = useState<string>('');
  const [resetTimer, setResetTimer] = useState<boolean>(false);

  console.log('OtpFormOrganism - data', data);

  useEffect(() => {
    if (actionData && actionData.error) {
      setError(
        actionData.details?.errorMessage ||
        actionData.error ||
        DATA_TEXT.verify.component.organisms.unexpectedError
      );
    } else {
      setError(null);
    }
  }, [data]);

  useEffect(() => {
    // Update the isMobileView state on component mount to ensure consistent client-side rendering
    setIsMobileView(isMobile());
  }, []);

  useEffect(() => {
    if (resendCount > 0) {
      setResendMessage(DATA_TEXT.verify.component.organisms.codeResent);
      const timer = setTimeout(() => setResendMessage(''), DATA_NUMERIC.verify.codeResentTimeout); // Clear message after 1 second
      return () => clearTimeout(timer);
    }
  }, [resendCount]);

  const handleResendCode = () => {
    submit(null, {
      method: ATTRIBUTES.organisms.verify.resend.useSubmit.method,
      action: ATTRIBUTES.organisms.verify.resend.useSubmit.action(method, data),
    });
    setResendCount((prevCount) => prevCount + 1);
    setTimeLeft(DATA_NUMERIC.verify.timerResetValue[process.env.NODE_ENV as 'development' | 'production']); // Reset the timer to 1 minute
    setResetTimer((prev) => !prev); // Toggle this state to trigger useEffect
  };


  useEffect(() => {
    setTimeLeft(DATA_NUMERIC.verify.timerResetValue[process.env.NODE_ENV as 'development' | 'production']);
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, DATA_NUMERIC.verify.timerInterval);

    return () => clearInterval(timer);
  }, [resetTimer]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const description = method === FORM_INPUT_NAME_PHONE
    ? DATA_TEXT.verify.component.organisms.descriptionPhone(data || '')
    : method === FORM_INPUT_NAME_EMAIL
      ? DATA_TEXT.verify.component.organisms.descriptionEmail(data || '')
      : DATA_TEXT.verify.component.organisms.descriptionDefault;

  return (
    <article>
      <HeaderMolecule description={`${description} 
      ${timeLeft ? `${DATA_TEXT.verify.component.organisms.codeExpiresIn} ${formatTime(timeLeft)}` : DATA_TEXT.verify.component.organisms.codeExpired}`}
      />
      <Form method={ATTRIBUTES.organisms.verify.form.Form.method}  
      id={ATTRIBUTES.organisms.verify.form.Form.id} 
      className={STYLE_CLASSES.component.organisms.verify.form.container}
      aria-label={ATTRIBUTES.organisms.verify.form.Form.ariaLabel}>
        <OtpInputs />
        <ErrorMessage errorText={errorText} />
        <div className={STYLE_CLASSES.component.organisms.verify.form.buttonWrapper(isMobileView)}>
          <ButtonAtom ref={submitButtonRef}
            text={navigation.state === 'submitting' ? DATA_TEXT.verify.component.organisms.loading : DATA_TEXT.verify.component.organisms.verifyAccount}
          />
        </div>
      </Form>
      <div className={STYLE_CLASSES.component.organisms.verify.form.resendContainer}>
        {DATA_TEXT.verify.component.organisms.didntReceiveCode}
        <button onClick={handleResendCode}
          //* Using to add timer to the resend button, we're passing -1 as the timeLeft value (no timer).
          className={STYLE_CLASSES.component.organisms.verify.form.resendButton(-1 /*timeLeft*/)}
        // className={STYLE_CLASSES.component.organisms.verify.form.resendButton(timeLeft)}
        // disabled={timeLeft > 0}
        >
          {/*//* (no timer) */}
          {/* {OTP_FORM_TEXT.resend} {timeLeft > 0 ? `(${formatTime(timeLeft)})` : ''} */}
          {DATA_TEXT.verify.component.organisms.resend}
        </button>
        {resendMessage && (
          <p className={STYLE_CLASSES.component.organisms.verify.form.resendMessage}>{resendMessage}</p>
        )}
      </div>
    </article>
  );
};

export default OtpFormOrganism;