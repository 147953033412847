import React from 'react';
import { STYLE_CLASSES } from '../../styles';

interface HeaderWrapperProps {
  className?: string; // Additional custom classes for the header
  children: React.ReactNode; // Children components to be rendered inside the header
}

/**
 * HeaderWrapper component - A reusable wrapper for header sections, providing consistent styling.
 * @param {string} [className] - Optional additional custom classes for the header.
 * @param {React.ReactNode} children - The children components to be rendered inside the header.
 * @returns {JSX.Element} A JSX element wrapping the header content.
 */
const HeaderWrapper: React.FC<HeaderWrapperProps> = ({ className, children }) => {

  return (
    <header className={STYLE_CLASSES.component.atoms.header(className)}>
      {children}
    </header>
  );
};

export default HeaderWrapper;
