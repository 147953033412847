import React, { useState, useEffect } from 'react';
import background from '../styles/background.png';
import { SpinnerComponent } from '@entrycall/component-spinner';
import { STYLE_CLASSES } from '../styles';

interface SectionWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  children: React.ReactNode;
}

const SectionWrapper: React.FC<SectionWrapperProps> = ({
  children,
  className,
  ...rest
}) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  useEffect(() => {
    const img = new Image();
    img.src = background;
    img.onload = () => setImageLoaded(true);

    // Add white background when component mounts
    document.body.style.backgroundColor = 'white';
    
    // Cleanup when component unmounts
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);

  if (!imageLoaded) {
    return (
      <div className={STYLE_CLASSES.pages.mainWrapper.spinnerContainer}>
        <SpinnerComponent />
      </div>
    );
  }

  return (
    <main className={STYLE_CLASSES.pages.mainWrapper.container(className)} 
    style={{ backgroundImage: `url(${background})` }}
    {...rest}
    >
      {children}
    </main>
  );
};

export default SectionWrapper;
