import React from 'react';

import ErrorMessage from '../../atoms/auth/error_message-auth.atom.ui';
import IconWrapper from '../../atoms/auth/icon_wrapper-auth.atom.ui';
import AuthErrorIcon from '../../atoms/auth/error_icon-auth.atom.ui';
import AuthInput from '../../atoms/auth/input-auth.atom.ui';
import AuthLabel from '../../atoms/auth/label-auth.atom.ui';
import { STYLE_CLASSES } from '../../../styles';

interface InputFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  name: string;
  type: string;
  placeholder: string;
  icon: React.ReactNode;
  errorText: string | null;
  isInvalid: boolean;
}

const AuthInputField: React.FC<InputFieldProps> = ({
  id,
  name,
  type,
  placeholder,
  icon,
  errorText,
  isInvalid,
  onChange,
  ...rest
}) => (
  <div className={STYLE_CLASSES.component.molecules.auth.inputField.container}>
    <AuthLabel htmlFor={id}></AuthLabel>
    <div className={STYLE_CLASSES.component.molecules.auth.inputField.inputContainer}>
      <IconWrapper>{icon}</IconWrapper>
      <AuthInput
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        isInvalid={isInvalid}
        onChange={onChange} // Pass the onChange handler
        {...rest} // Spread other input attributes
      />
      {isInvalid && <AuthErrorIcon />}
    </div>
    {isInvalid && <ErrorMessage id={id} errorText={errorText} />}
  </div>
);

export default AuthInputField;
