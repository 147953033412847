export const STYLE_CLASSES = {
    default: {
        container: (customClass: string): string => `flex justify-center items-center h-full ${customClass}`,
        img: "w-96 h-96 max-w-full",
    },
    ping: {
        container: "flex justify-center items-center h-svh",
        pingWrapper: "relative flex h-3 w-3",
        pingAnimation: "animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75",
        pingDot: "relative inline-flex rounded-full h-3 w-3 bg-sky-500"
    }
}