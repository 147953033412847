import classNames from 'classnames';
import { baseStyles, getThemeClasses } from '@entrycall/ui-tailwind';

const mode = 'dark';
const themeClasses = getThemeClasses(mode);

export const STYLE_CLASSES = {
    component: {
        atoms: {
            text: {
                p: classNames(
                    'text-slate-500',
                    'iPhone:text-xs',
                    'tablet:text-base'
                ),
            },
            button: classNames(
                'w-full',
                'inline-flex',
                'justify-center',
                'whitespace-nowrap',
                baseStyles.borderRadius.large.rounded.default,
                themeClasses.backgroundColor,
                'hover:opacity-75',
                'px-3.5',
                'py-2.5',
                'text-sm',
                'font-medium',
                'text-white',
                'shadow-sm',
                'shadow-indigo-950/10',
                'focus:outline-none',
                'focus:ring',
                themeClasses.focusRingColor,
                'focus-visible:outline-none',
                'focus-visible:ring',
                'focus-visible:opacity-35',
                'transition-colors',
                'duration-150'
            ),
            header: (className?: string) => classNames(
                'flex',
                'flex-col',
                'items-center',
                'text-center',
                'space-y-4',
                'max-w-md',
                'mx-auto',
                'mb-4',
                'iPhone:p-0',
                className
            ),
            auth: {
                authErrorIcon: {
                    errorIconContainer: classNames(
                        'pointer-events-none',
                        'absolute',
                        'inset-y-0',
                        'right-0',
                        'flex',
                        'items-center',
                        'pr-3'
                    ),
                    errorIcon: classNames(
                        'h-5',
                        'w-5',
                        'text-red-500'
                    ),
                },
                errorMessage: classNames(
                    'mt-2',
                    'text-sm',
                    'text-red-600'
                ),
                iconWrapper: classNames(
                    'pointer-events-none',
                    'absolute',
                    'inset-y-0',
                    'left-0',
                    'flex',
                    'items-center',
                    'px-3',
                    'border-r-2'
                ),
                input: {
                    base: classNames(
                        'form-input',
                        'block',
                        'w-full',
                        baseStyles.borderRadius.medium.rounded.default,
                        'border',
                        'pl-14',
                        'py-1.5',
                        'pr-10',
                        'transition-colors',
                        'duration-300',
                        'ease-in-out'
                    ),
                    textColor: (isInvalid: boolean) =>
                        isInvalid
                            ? 'text-red-900 border-red-300 placeholder:text-red-300'
                            : 'black border-gray-300',
                    focusStyles: 'focus:outline-none focus:border-blue-500',
                    transition: 'background-color 5000s ease-in-out',
                },
                label: classNames(
                    'block',
                    'text-sm',
                    'font-medium',
                    'leading-6',
                    'text-gray-900'
                ),
            },
            verify: {
                link: classNames(
                    'font-medium',
                    'text-indigo-500',
                    'hover:text-indigo-600'
                ),
                otpInput: classNames(
                    'mx-1',
                    'w-11',
                    'h-11',
                    'text-center',
                    'text-2xl',
                    'font-extrabold',
                    'text-slate-900',
                    'bg-slate-100',
                    'border',
                    'border-transparent',
                    'hover:border-slate-200',
                    'appearance-none',
                    'rounded',
                    'outline-none',
                    'focus:bg-white',
                    'focus:border-indigo-400',
                    'focus:ring-2',
                    'focus:ring-indigo-100',
                    'iPhone:p-0',
                    'tablet:p-4',
                    'tablet:mx-0',
                    'tablet:w-14',
                    'tablet:h-14'
                ),
            },
        },
        molecules: {
            auth: {
                email: {
                    inputFields: {
                        icon: (isInvalid?: boolean) => classNames(
                            'h-5',
                            'w-5',
                            isInvalid ? 'text-red-900' : 'text-gray-500'
                        ),
                    }
                },
                phone: {
                    inputFields: {
                        icon: (isInvalid?: boolean) => classNames(
                            'h-4',
                            'w-4',
                            isInvalid ? 'text-red-900' : 'text-gray-500'
                        ),
                    }
                },
                inputField: {
                    container: classNames('mt-4'),
                    inputContainer: classNames(
                        'relative',
                        'mt-2',
                        baseStyles.borderRadius.medium.rounded.default,
                        'rounded-md',
                        'shadow-sm',
                        'border'
                    ),
                },
            },
            verify: {
                otpInput: classNames(
                    'flex',
                    'items-center',
                    'justify-center',
                    'tablet:gap-3'
                ),
            },
            header: {
                svg: classNames(themeClasses.fillSVG),
                h2Classes: classNames(
                    'font-bold',
                    'iPhone:mb-5',
                    'iPhone:text-2xl',
                    'tablet:text-3xl'
                ),
            },
        },
        organisms: {
            auth: {
                form: {
                    article: 'bg-white',
                    form: 'max-w-md mx-auto',
                    tabGroup: classNames(
                        'group',
                        'flex',
                        'items-center',
                        'bg-gray-100',
                        'p-2',
                        baseStyles.borderRadius.large.rounded.default
                    ),
                    tab: classNames(
                        'w-1/2',
                        baseStyles.borderRadius.full.rounded,
                        'border',
                        'border-transparent',
                        'px-3',
                        'py-1.5',
                        'text-sm',
                        'font-medium',
                        'text-gray-500',
                        'hover:text-gray-900',
                        'data-[selected]:bg-white',
                        'data-[selected]:text-gray-900'
                    ),
                    tabPanels: 'mt-2',
                    tabPanel: 'p-0.5',
                    buttonWrapper: (isMobileView: boolean) => `mx-auto mt-4 ${!isMobileView ? 'max-w-[260px]' : ''}`,
                },
            },
            verify: {
                form: {
                    container: classNames('max-w-md', 'mx-auto'),
                    buttonWrapper: (isMobileView: boolean) => classNames('mx-auto', 'mt-4', {
                        'max-w-[260px]': !isMobileView
                    }),
                    resendContainer: classNames('text-sm', 'text-slate-500', 'mt-4'),
                    resendButton: (timeLeft: number) => classNames('text-blue-600', 'hover:text-blue-800', 'ml-1', {
                        'opacity-50 cursor-not-allowed': timeLeft > 0
                    }),
                    resendMessage: classNames('text-green-600', 'mt-2')
                },
            },
        },
    },
    pages: {
        auth: {},
        verify: {},
        mainWrapper: { 
            container: (className?: string) => classNames(
                className,
                'min-h-screen',
                'flex',
                'justify-center',
                'items-center',
                'tablet:min-h-screen',
                'bg-cover',
                'bg-center',
                'bg-no-repeat',
            ),
            spinnerContainer: classNames(
                'fixed',
                'inset-0',
                'flex',
                'items-center',
                'justify-center',
                'bg-white',
            ),
        },
    },
    templates: {
        sectionWrapper: (className?: string) => classNames(
            'm-auto',
            'w-full',
            'max-w-xs',
            'p-2',
            'container',
            'mx-auto',
            'bg-white',
            'place-items-center',
            'text-center',
            'px-4',
            'py-10',
            baseStyles.borderRadius.extraLarge.rounded.default,
            'shadow',
            'tablet:container',
            'tablet:max-w-xl',
            'tablet:p-2',
            'tablet:px-8',
            'tablet:py-10',
            'tablet:place-items-center',
            'tablet:text-center',
            className,
        ),
    },
} as const;