export const DATA_NUMERIC = {
    auth:{

    },
    verify:{
        resendCountInitial: 0,
        timeLeftInitial: 180,  // 3 minutes in seconds
        resendButtonDisabledOpacity: 0.5,
        resendButtonEnabledOpacity: 1,
        codeResentTimeout: 1000,  // 1 second
        timerInterval: 1000,      // 1 second intervals
        timerResetValue: {
            development: 3,
            production: 180
        },
    },
} as const;