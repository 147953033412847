export const FORM_INPUT_NAME_EMAIL = 'email';
export const FORM_INPUT_NAME_PHONE = 'phone';


export type LoginMethod = typeof FORM_INPUT_NAME_EMAIL | typeof FORM_INPUT_NAME_PHONE;


export type LoginData = {
    identifier: string;
    loginMethod: LoginMethod;
  }

export type IVerifyAction = {
    token: string | null;
    refreshToken: string | null;
    error: string | null;
    details: any | null;
  }

  export type ErrorResponse = {
    message: string;
    status: number;
    data: {
      status?: number;
      errorCode?: string;
      errorMessage?: string;
      errorTime?: string;
    };
  }
  
  export type FormattedError = {
    error: string;
    details: {
      status?: number;
      errorCode?: string;
      errorMessage?: string;
      errorTime?: string;
    };
  }

  export type RedirectToUrlParams = {
    session: any;
    url: string;
    commitSession: Function;
  };
  