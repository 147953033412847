import React, { useEffect, useRef, useState } from 'react';
import OtpInput from '../../atoms/verify/otp-input-verfiy.atom.ui';
import { STYLE_CLASSES } from '../../../styles';

/**
 * OtpInputs component - A group of OTP input fields to capture a multi-digit OTP code.
 */
const OtpInputs: React.FC = () => {
  const [otp, setOtp] = useState(new Array(6).fill('')); // State to hold the OTP values
  const [activeOTPIndex, setActiveOTPIndex] = useState(0); // State to track the active OTP input index
  const inputsRef = useRef<(HTMLInputElement | null)[]>([]); // Ref to store references to the OTP input elements

  /**
   * Handles changes in the OTP input fields.
   * @param {React.ChangeEvent<HTMLInputElement>} target - The event target containing the input value.
   * @param {number} index - The index of the OTP input field being changed.
   */
  const handleOnChange = (
    { target }: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const { value } = target;
    const newOtp = [...otp];
    if (value === '' || /^[0-9]$/.test(value)) {
      // Allow only digits or empty value
      newOtp[index] = value.substring(value.length - 1); // Only take the last character entered
      setOtp(newOtp);

      if (value !== '' && index < otp.length - 1) {
        setActiveOTPIndex(index + 1); // Move focus to the next input field if available
      }
    } else {
      target.value = otp[index];
    }
  };

  /**
   * Handles key down events in the OTP input fields.
   * @param {React.KeyboardEvent<HTMLInputElement>} e - The keyboard event.
   * @param {number} index - The index of the OTP input field.
   */
  const handleOnKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (e.key === 'Backspace') {
      if (otp[index] === '') {
        if (index > 0) {
          setActiveOTPIndex(index - 1); // Move focus to the previous input field if the current one is empty
        }
      } else {
        const newOtp = [...otp];
        newOtp[index] = ''; // Clear the current input field
        setOtp(newOtp);
      }
    }
  };

  /**
   * Sets focus on the currently active OTP input field.
   */
  useEffect(() => {
    inputsRef.current[activeOTPIndex]?.focus();
  }, [activeOTPIndex]);

  /**
   * Handles the focus event on the OTP input fields.
   * @param {React.FocusEvent<HTMLInputElement>} e - The focus event.
   */
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select(); // Select the content of the input field on focus
  };



  return (
    <div className={STYLE_CLASSES.component.molecules.verify.otpInput}>
      {otp.map((_, index) => (
        <OtpInput
          key={index}
          value={otp[index]}
          index={index}
          inputRef={(el) => {
            if (inputsRef.current) {
              inputsRef.current[index] = el; // Store the reference of each input element
            }
          }}
          handleChange={handleOnChange}
          handleKeyDown={handleOnKeyDown}
          handleFocus={handleFocus}
        />
      ))}
    </div>
  );
};

export default OtpInputs;
