import React from 'react';
import { STYLE_CLASSES } from '../../styles';

interface TextAtomProps extends React.HTMLAttributes<HTMLParagraphElement> {
  text: string;
}

const TextAtom: React.FC<TextAtomProps> = ({ text, ...props }) => {
  return (
    <p className={STYLE_CLASSES.component.atoms.text.p} {...props}>
      {text}
    </p>
  );
};

export default TextAtom;
